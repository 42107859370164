@import url(https://fonts.googleapis.com/css2?family=Italianno&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Special+Elite&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

*{font-family:"Nunito", sans-serif}.slide-3{display:flex}

.home-bg{position:absolute;height:100vh;width:100vw}.home-page{position:relative;text-align:center;font-size:3vmin;padding:1px;font-weight:normal;height:90vh;display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;color:#fff}#avatar img{border-radius:50%}.down-arrow{cursor:pointer;margin:5vmin}@media screen and (max-width: 600px){.home-page p{padding:1px;font-size:4vmin}.home-page img{border-radius:50%;width:60vmin;height:60vmin}}

.custom-navbar{position:relative;text-align:center;height:10vh;padding:1vmin 0vmin}

.footer{background-color:#1b1c1d;height:15vh;display:flex;flex-direction:column-reverse;justify-content:space-evenly;align-content:center;align-items:center;flex-wrap:wrap;padding-top:1vmin;padding-right:2vmin;padding-left:1vmin;color:wheat}.footer span{font-size:2vmin}.contacts{display:flex}.contacts div{margin:0 0.5rem}.copyrights{display:flex;align-items:center;align-content:center}.copyrights p,.copyrights img{margin-bottom:0;margin-left:0.5rem}@media screen and (max-width: 600px){.footer span{font-size:3vmin}}

#projects{height:100vh;width:100vw;font-size:10vmin}#projects img{height:45vmin;width:45vmin}.cards{height:100vh;width:100vw;background-color:#7dc2af;display:flex;justify-content:space-evenly;align-items:center;flex-direction:row;flex-wrap:wrap;margin:auto}.visible{background-color:#7dc2af;height:45vmin;width:45vmin}.hidden{color:black;height:45vmin;width:45vmin}@media screen and (max-width: 600px){.cards{height:100vh;display:grid;padding-top:2vh;padding-bottom:2vh;grid-template-areas:"a ." ". b" "c ." ". d"}div:nth-child(1){grid-area:a}div:nth-child(2){grid-area:b}div:nth-child(3){grid-area:c}div:nth-child(4){grid-area:d}.visible{background-color:#7dc2af;height:24vh}.hidden{color:black;height:24vh}#projects img{height:24vh}}

#about{background-color:#edf5e1;height:85vh;width:100vw;display:flex;flex-direction:column;justify-content:space-around;align-content:center;flex-wrap:wrap;align-items:center}#about p span{color:teal;font-weight:bold;font-family:"Rajdhani", sans-serif}.location{width:40vw}.about-section{text-align:center;font-size:4vmin;width:75vw}@media screen and (max-width: 600px){#about{display:flex;flex-direction:column;justify-content:space-evenly;align-content:center;align-items:center}.location{width:80vw}.about-section{text-align:center;font-size:4vmin;width:80vw}}

