.home-bg {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.home-page {
  position: relative;
  text-align: center;
  font-size: 3vmin;
  padding: 1px;
  font-weight: normal;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
}
#avatar {
  // width: 50%;
  // height: 50%;

  img {
    border-radius: 50%;
  }
}
.down-arrow {
  cursor: pointer;
  margin: 5vmin;
}

@media screen and (max-width: 600px) {
  .home-page p {
    padding: 1px;
    font-size: 4vmin;
  }
  .home-page {
    img {
      border-radius: 50%;
      width: 60vmin;
      height: 60vmin;
    }
  }
}
