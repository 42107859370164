#projects {
  height: 100vh;
  width: 100vw;
  font-size: 10vmin;
  img {
    height: 45vmin;
    width: 45vmin;
  }
}
.cards {
  height: 100vh;
  width: 100vw;
  background-color: rgb(125, 194, 175);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}
.visible {
  background-color: rgb(125, 194, 175);
  height: 45vmin;
  width: 45vmin;
}
.hidden {
  color: black;
  height: 45vmin;
  width: 45vmin;
}

@media screen and (max-width: 600px) {
  .cards {
    height: 100vh;
    display: grid;
    padding-top: 2vh;
    padding-bottom: 2vh;
    grid-template-areas:
      "a ."
      ". b"
      "c ."
      ". d";
  }
  div:nth-child(1) {
    grid-area: a;
  }
  div:nth-child(2) {
    grid-area: b;
  }
  div:nth-child(3) {
    grid-area: c;
  }
  div:nth-child(4) {
    grid-area: d;
  }
  .visible {
    background-color: rgb(125, 194, 175);
    height: 24vh;
  }
  .hidden {
    color: black;
    height: 24vh;
  }
  #projects {
    img {
      height: 24vh;
    }
  }
}
