#about {
  background-color: #edf5e1;
  height: 85vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  p span {
    color: teal;
    font-weight: bold;
    font-family: "Rajdhani", sans-serif;
  }
}
.location {
  width: 40vw;
}
.about-section {
  text-align: center;
  font-size: 4vmin;
  width: 75vw;
}

@media screen and (max-width: 600px) {
  #about {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }
  .location {
    width: 80vw;
  }
  .about-section {
    text-align: center;
    font-size: 4vmin;
    width: 80vw;
  }
}
