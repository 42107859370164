.footer {
  background-color: rgb(27, 28, 29);
  height: 15vh;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1vmin;
  padding-right: 2vmin;
  padding-left: 1vmin;
  color: wheat;
  span {
    font-size: 2vmin;
  }
}
.contacts {
  display: flex;
  div {
    margin: 0 0.5rem;
  }
}
.copyrights {
  display: flex;
  align-items: center;
  align-content: center;

  p,
  img {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    span {
      font-size: 3vmin;
    }
  }
}
